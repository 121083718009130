import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aebab876"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "progress"
};
export default {
  __name: 'dProgress',
  props: ["count"],
  setup(__props) {
    const props = __props;

    // let emit = defineEmits(["update:modelValue"]);

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
        class: "progress_portion",
        style: _normalizeStyle({
          '--count': __props.count + '%'
        })
      }, null, 4)]);
    };
  }
};