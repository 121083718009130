import "core-js/modules/es.array.push.js";
import { defineStore } from "pinia";
import store from "@/store";
import { getUserInfo, loginApi, get_user_list, sales_area } from "@/api/apis";
import { randomColor } from "@/util/index";
import { reactive } from "vue";
export const useUserStore = defineStore("useUserStore", () => {
  let seen = ref(false);
  let is_active_menu = ref(0);
  let user_info = ref("");
  user_info.value = localStorage.getItem("userInfo_sys") && JSON.parse(localStorage.getItem("userInfo_sys"));
  let page_size = ref(2); //页面放大器
  let search_value = ref("");
  let apps_list = reactive([]);
  let device = ref("");
  const handle_apps_info = obj => {
    // console.log("打印obj===", obj);
    let item = apps_list.find(x => x.fileName == obj.fileName);
    if (item) {
      item['downloadedBytes'] = obj.downloadedBytes;
      item['total'] = obj.total;
      item['chunks'] = obj.chunks;
      item['fileName'] = obj.fileName;
    } else {
      apps_list.push({
        downloadedBytes: obj.downloadedBytes,
        total: obj.total,
        chunks: obj.chunks,
        fileName: obj.fileName
      });
    }
  };
  const get_apps_item = fileName => {
    let item = apps_list.find(x => x.fileName == fileName);
    return item;
    // if (item) {
    //   return item;
    // } else {
    //   return null;
    // }
  };

  let toast_mode = reactive({
    type: "error",
    duration: "3",
    message: ""
  });
  let show_mode = ref(false);
  const set_seen = val => {
    seen.value = val;
  };
  const set_active_menu = val => {
    is_active_menu.value = val;
  };
  const set_user_info = val => {
    user_info.value = val;
  };
  const set_page_size = val => {
    page_size.value = val;
  };
  const set_toast_mode = obj => {
    show_mode.value = true;
    if (obj.hasOwnProperty("type")) {
      toast_mode.type = obj.type;
    }
    if (obj.hasOwnProperty("duration")) {
      toast_mode.duration = obj.duration;
    }
    if (obj.hasOwnProperty("message")) {
      toast_mode.message = obj.message;
    }
  };
  let timer;
  const set_search_value = val => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    timer = setInterval(() => {
      search_value.value = val;
    }, 500);
  };
  const set_device = val => {
    device.value = val;
  };
  return {
    seen,
    set_seen,
    set_active_menu,
    set_user_info,
    is_active_menu,
    user_info,
    page_size,
    set_page_size,
    set_search_value,
    search_value,
    set_toast_mode,
    apps_list,
    toast_mode,
    show_mode,
    handle_apps_info,
    get_apps_item,
    device,
    set_device
  };
}, {
  persist: {
    enabled: true,
    strategies: [{
      key: "userStoreHook",
      storage: localStorage
    }]
  }
});

// 非setup
export function useUserStoreHook() {
  return useUserStore(store);
}