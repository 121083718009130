import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    redirect: '/home',
    children: [
      {
        path: "home",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
      },
      {
        path: "opinion",
        name: "opinion",
        component: () => import(/* webpackChunkName: "home" */ '../views/opinion.vue'),
      },
      {
        path: "business",
        name: "business",
        component: () => import(/* webpackChunkName: "home" */ '../views/business.vue'),
      },
      {
        path: "about",
        name: "about",
        component: () => import(/* webpackChunkName: "home" */ '../views/about.vue'),
      },
      {
        path: "development",
        name: "development",
        component: () => import(/* webpackChunkName: "home" */ '../views/development.vue'),
      },
      {
        path: "software",
        name: "software",
        component: () => import(/* webpackChunkName: "home" */ '../views/software_mall.vue'),
      },
      {
        path: "app_detail/:fid",
        name: "app_detail",
        component: () => import(/* webpackChunkName: "app_detail" */ '../views/app_detail.vue')
      }, {
        path: "sendCommont",
        name: "sendCommont",
        component: () => import(/* webpackChunkName: "sendCommont" */ '../views/sendCommont.vue')
      }
    ]
  },
  {
    path: "/down_text",
    name: "down_text",
    component: () => import(/* webpackChunkName: "down_text" */ '../views/down_text.vue')
  },
  {
    path: '/login',
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "login" */ '../views/register.vue'),
  },
  {
    path: "/iframe",
    name: "iframe",
    component: () => import(/* webpackChunkName: "login" */ '../views/iframe.vue'),
  }
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
  // },
  // {
  //   path: '/book',
  //   name: 'book',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/book.vue')
  // },
  // {
  //   path: '/book_detail',
  //   name: 'book_detail',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/book_detail.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // mode: "hash",
  routes
})

router.beforeEach((to, from, next) => {
  console.log("打印to", to);
  console.log("打印from", from);
  if (to.path == "/software") {
    if (localStorage.getItem('token_sys')) {
      next()
    } else {
      next({
        path: "/login", query: {
          redirect: to.path
        }
      })
    }
  } else {
    next()

  }
})

export default router
