
import axios from "axios";
import { useUserStoreHook } from "@/store/user"
let store;
setTimeout(() => {
    store = useUserStoreHook()
}, 0);
// import fs from "fs"
import { ElMessage, ElMessageBox, ElLoading } from "element-plus"
import pinyin from "js-pinyin";

function add0(m) {
    return m < 10 ? "0" + m : m;
}
export function format(shijianchuo) {
    //shijianchuo是整数，否则要parseInt转换
    if (!shijianchuo) {
        return "-";
    }
    var time = new Date(shijianchuo * 1000);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return (
        y +
        "-" +
        add0(m) +
        "-" +
        add0(d) +
        " " +
        add0(h) +
        ":" +
        add0(mm) +
        ":" +
        "00"
    );
}

export function format_y_m(shijianchuo) {
    //shijianchuo是整数，否则要parseInt转换
    if (!shijianchuo) {
        return "-";
    }
    var time = new Date(shijianchuo * 1000);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;

    return (
        y +
        " 年 " +
        m +
        " 月"
    );
}

/**
 * @description: 根据某一天获取本月的第一天及最后一天（不传默认为本月第一天）
 * @param {*}  timestamp:  某天时间
 * @Author: zhs
 * @return 毫秒数
 */
export function getMonthFrist(timestamp) {
    let date = timestamp ? new Date(timestamp) : new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    return new Date(year, month, 1) * 1
}
export function getMonthLast(timestamp) {
    let date = timestamp ? new Date(timestamp) : new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    // day为0表示获取上一个月最后一天，所以month+1  
    // 86300表示一天秒数，最后日期为 23:59:59
    return (new Date(year, month + 1, 0) / 1000 + 86399) * 1000
}
export function getWeekFrist(timestamp) {
    let date = timestamp ? new Date(timestamp) : new Date()
    let week_num = date.getDay() == 0 ? 7 : date.getDay()
    let week_first = new Date(date.setDate(date.getDate() - week_num + 1))
    let year = week_first.getFullYear()
    let mounth = week_first.getMonth()
    let day = week_first.getDate()
    // day为0表示获取上一个月最后一天，所以month+1  
    // 86300表示一天秒数，最后日期为 23:59:59
    return new Date(year, mounth, day) * 1
}

export function getWeekLast(timestamp) {
    let date = timestamp ? new Date(timestamp) : new Date()
    let week_num = date.getDay() == 0 ? 7 : date.getDay()
    let week_end = new Date(date.setDate(date.getDate() + (7 - week_num)))
    let year = week_end.getFullYear()
    let mounth = week_end.getMonth()
    let day = week_end.getDate()
    // day为0表示获取上一个月最后一天，所以month+1  
    // 86300表示一天秒数，最后日期为 23:59:59
    return (new Date(year, mounth, day) / 1000 + 86399) * 1000
}
export function randomColor() {
    var arrHex = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"],
        strHex = "#",
        index;
    for (var i = 0; i < 6; i++) {
        index = Math.round(Math.random() * 15);
        strHex += arrHex[index];
    }
    return strHex;
}

export function getOneArr(arr, children = "children") {

    let data = JSON.parse(JSON.stringify(arr));
    let newData = [];
    const callback = (item) => {
        (item[children] || (item[children] = [])).map((v) => {
            callback(v);
        });
        delete item[children];
        newData.push(item);
    };
    data.map((v) => callback(v));
    return newData;
}
// let week_second = new Date(date.setDate(date.getDate() + 6))

/**
 * 取出字符串中a标签的跳转链接与内容
 * @param {} richtxt 
 * @returns 
 */
export function getRichLinks(richtxt) {
    const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/gi;
    let links = []; // 存储匹配结果的数组
    let match; // 使用正则表达式进行匹配
    while ((match = regex.exec(richtxt)) !== null) {
        const href = match[1]; // 提取href属性
        const text = match[2]; // 提取文本内容
        // 可以根据需要提取其他属性

        links.push({
            href,
            text
        });
    }
    return links;
}

/**
 * 截取富文本中的video标签
 * @param {*} html 
 * @returns 
 */
export function getvideoFromRichtext(html) {
    // const regex = /<video.*?src="(.*?)".*?>/g;
    // const html = '<div><p>这是一段富文本，其中包含了source：<source src="example.mp4"></source></p></div>';
    const regex = /<source.*?src=['"](.*?)['"].*?>/g;
    const srcList = [];
    let match;
    while ((match = regex.exec(html)) !== null) {
        srcList.push(match[1]);
    }
    // console.log('srcList----->',srcList); // 输出 example.mp4
    return srcList;
}

/**
 * 
 * @param {*} 树形结构数组 
 * @param {*} 要查找的id
 * @returns 
 */
export function getTree(arr, id) {
    let pid_s = [];
    function get_pid(arr2, id) {
        arr2.forEach((item) => {
            if (item.id == id) {
                pid_s.push(item);

                if (item.pid != 75) {
                    get_pid(arr, item.pid);
                }
                return;
            } else if (item.children && item.children.length != 0) {
                get_pid(item.children, id);
            }
        });
    }
    // let tree_data = [];
    // console.log("1111====", new Date().getTime());
    get_pid(arr, id);

    return pid_s.reverse();
};


/**
* 
* @param {*} 树形结构数组 
* @param {*} 要查找的id
* @returns 
*/
export function treeById(arr, id) {
    let result = ""
    function get_pid(arr2, id) {
        arr2.forEach((item) => {
            if (item.id == id) {
                result = item
                return;
            } else if (item.children && item.children.length != 0) {
                get_pid(item.children, id);
            }
        });
    }
    // let tree_data = [];
    // console.log("1111====", new Date().getTime());
    get_pid(arr, id);

    return result
};

export function de_weight(arr) {
    let map = new Map();
    arr.map((x) => {
        if (!map.has(x.id + x.name)) {
            map.set(x.id + x.name, x);
        }
    });
    return [...map.values()];
};

/**
 * 替换富文本标签
 */
export function replaceHtmlTags(text) {
    if (!text) {
        return false
    }
    var reg = /<[^<>]+>/g;//1、全局匹配g肯定忘记写,2、<>标签中不能包含标签实现过滤HTML标签
    text = text?.replace(/<img.*?>/g, '[图片]')?.replace(reg, '');//替换HTML标签
    text = text?.replace(/&nbsp;/ig, '');//替换HTML空格
    return text;
};
/**
 * 文件导出
 */
function createObjectURL(object) {
    return window.URL
        ? window.URL.createObjectURL(object)
        : window.webkitURL.createObjectURL(object);
};
export function exportByXML(obj, url, name) {
    let baseURL = process.env.VUE_APP_BASE_URL;

    var xhr = new XMLHttpRequest();
    var formData = new FormData();
    let params = toParams(obj);
    xhr.open("get", baseURL + url + "?" + params); //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
    xhr.setRequestHeader(
        "Authorization",
        "Bearer " + localStorage.getItem("token_blk")
    );
    xhr.setRequestHeader("system-type", 3);
    xhr.responseType = "blob";

    let loading = ElLoading.service({
        text: "加载中...",
        background: "rgba(0,0,0,0.7)",
        spinner: "el-icon-loading",
    });
    xhr.onload = function (e) {
        console.log("导出e#####", e);
        loading.close()
        if (this.status == 200) {
            var blob = this.response;
            // var filename = params.fileName;
            // console.log(this.response)
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob);
            } else {
                var a = document.createElement("a");
                var url = createObjectURL(blob);
                a.href = url;
                a.download = name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }
    };
    xhr.send(formData);
};

function toParams(param) {
    var result = "";
    for (let name in param) {
        if (typeof param[name] != "function") {
            result += "&" + name + "=" + encodeURI(param[name]);
        }
    }
    return result.substring(1);
};

export function shuffle(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}

/**
 * 获取当前时间及未来五天时间的数组
 * time为开始的时间,count为要获取几天的数量
 */

export function get_last_time(time, count) {
    // let Date=new Date(time*1000)
    let date_arr = []
    for (let index = 0; index <= count; index++) {

        let date = new Date(time * 1000 + index * (24 * 60 * 60 * 1000))
        date_arr.push(date)
    }
    return date_arr
}
export function down_apk(data, appName) {

    const file = new Blob([data], { type: "application/octet-stream" });

    // 使用 File API 在本地保存文件
    const url_temp = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url_temp;
    link.setAttribute("download", appName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
async function convertToBlob(binaryData) {
    try {
        // 创建一个 ReadableStream 对象
        const readableStream = new ReadableStream({
            start(controller) {
                // 将二进制数据推送到控制器
                controller.enqueue(binaryData);
                controller.close();
            }
        });

        // 使用 ReadableStream 创建 Blob 对象
        const blob = await new Response(readableStream).blob();
        return blob;
    } catch (error) {
        console.error('Error converting to Blob:', error);
        throw error;
    }
}

let timer = null

async function fetchfile(url, appName, item) {

    // url = url.replace("http://112.53.105.99:20001", "/upload_app")
    // console.log("$$$$$$$$$$$$$$$$$打印url======", url, "appName===", appName, "item===", item);
    // console.log("$$$$$$$$$$$$$$$$$", item);
    try {
        const response = await fetch(url, {
            headers: {
                Range: `bytes=${item.downloadedBytes}-`,
            },
            signal: item.downloadController.signal,
        })
        // console.log("打印response=======", response);
        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`)
        }
        if (!item.total) {
            item["total"] = parseInt(response.headers.get("Content-Length"), 10)
        }

        const blob = await new Response(
            new ReadableStream({
                async start(controller) {
                    const reader = response.body.getReader()
                    // getProgress(item.appName + "_down").then(progressData => {
                    //     console.log("在235465767progressData===", progressData);

                    // })
                    // let progressData = store.get_apps_item(item.appName + "_down")
                    // console.log("打印progressData====", progressData);
                    // store.set_toast_mode({
                    //     type: "success",
                    //     message: "没有progressD",
                    // });


                    if (item.chunks != 0) {
                        // console.log('将chunks--------添加给controller');
                        item.chunks.forEach(x => {
                            // console.log("打印x=====", x);
                            controller.enqueue(x)
                        });
                    }
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            controller.close()
                            break;
                        }
                        item['chunks'].push(value);
                        item['downloadedBytes'] += value.length;
                        controller.enqueue(value)
                        if (timer) {
                            clearTimeout(timer)
                            timer = null
                        }
                        let progress = Number(Number((item['downloadedBytes'] / item['total']) * 100).toFixed(2));
                        // saveData(item.appName + "_down", item['chunks'])
                        // saveProgress(item.appName + "_down", item['downloadedBytes'], item["total"])
                        // console.log("设置progress___________", progress);
                        item.rate = progress
                        item.rate_text = progress + "%"

                    }
                }
            })
        ).blob()
        deleteProgress(item.appName + "_down")
        // console.log("打印item.blob=", blob);
        item.is_down = false
        item.isPaused = false
        saveBinaryData(blob, appName + "-" + item.version)
        setTimeout(() => {
            down_apk(blob, appName)
        }, 0);
    } catch (error) {
        // console.log("执行error=======================================", error);
        // item.is_down = false
    } finally {
        // console.log("执行finally=====================================");
        // item.is_down = false
        // item.isPaused = false
        // this.isDownloading = false;
        // this.isPaused = false;
    }
}

async function startDownload(item) {
    item['is_down'] = true
    item["downloadController"] = new AbortController()

    let process_data = await getProgress(item.appName + "_down")

    let { downloadedSize, id, total } = process_data

    if (process_data) {
        item["downloadedBytes"] = downloadedSize
        item["total"] = total
        let values = await loadBinaryData(item.appName + "_down")
        // console.log("从缓存中拿到的数据是======", values);
        item["chunks"] = [values]
    }

    fetchfile(item.appPathOss, item.appName + ".apk", item)
    // console.log("在startDownload=====", item);


    // getProgress(item.appName + "_down").then(progressData => {
    //     console.log("打印progressData", progressData);
    //     if (progressData) {
    //         // controller.enqueue(progressData.chunk)
    //         item['downloadedBytes'] = progressData.downloadedSize
    //         item['total'] = progressData.total
    // let progress = Number(Number((item['downloadedBytes'] / item['total']) * 100).toFixed(2));
    // // console.log("设置progress___________", progress);
    // item['rate'] = progress
    // item['rate_text'] = progress + "%"
    //         item['chunks'] = progressData.chunk
    //     } else {

    //     }



    // })

}
export function pauseDownload(item) {
    item.downloadController.abort()
    item.is_down = false
    item.isPaused = true
    // console.log("在pauseDownload=====", item);
}
export async function continueDownload(item) {
    // console.log("继续执行====", item);

    startDownload(item)
    item.isPaused = false
}

/**
 * 
 */
export async function downloadFile2(url, appName, item) {
    console.log("下载的url====", url);
    // let progressData = store.get_apps_item(item)
    // if (progressData) {
    //     console.log("点击开始继续下载============", progressData);
    //     item['downloadedBytes'] = progressData.downloadedBytes
    //     item['total'] = progressData.total
    //     item['chunks'] = progressData.chunks
    //     let progress = Number(Number((item['downloadedBytes'] / item['total']) * 100).toFixed(2));
    //     // console.log("设置progress___________", progress);
    //     item['rate'] = progress
    //     item['rate_text'] = progress + "%"
    // } else {

    // }

    item['chunks'] = []
    item['isPaused'] = false
    item['rate'] = 0
    item['rate_text'] = "0%"
    item['downloadedBytes'] = 0

    startDownload(item)

    // item['is_down'] = true


    // console.log("打印store====", store);
    // store.set_toast_mode({ type: "error", message: "测试测试" })
    //判断缓存中是否有下载数据
    // let b = getLocalStore("apps_info", {
    //     version: item.version,
    //     appName: appName
    // })
    // let item_app = store.get_apps_item({
    //     appName,
    //     version: item.version
    // })

    // if (!item_app) {
    //     console.log("没有得到缓存去请求下载接口");
    //     downloadExcelFile(url, appName, item)
    // } else {
    //     console.log("得到缓存的数据====", item_app);
    //     downlinkurl(item_app.blob, item_app.appName)
    // }

}




function setText(blob) {
    console.log("执行text方法", window.navigator.msSaveOrOpenBlob);
}

function downlinkurl(blob, fileName) {
    const link = document.createElement("a");
    const body = document.querySelector("body");
    let objectUrl = window.URL.createObjectURL(blob)
    link.href = objectUrl;
    link.download = fileName;

    // fix Firefox
    link.style.display = "none";
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    // window.URL.revokeObjectURL(link.href);
}

function saveAs(blob, filename, version) {
    console.log("执行saveAssaveAssaveAs");
    if (window.navigator.msSaveOrOpenBlob) {
        let result = navigator.msSaveBlob(blob, filename);
    } else {
        console.log("打印blob---", blob);
        // let objectUrl = window.URL.createObjectURL(blob)
        store.handle_apps_info({
            appName: filename,
            version,
            blob: blob
        })
        // setLocalStore("apps_info", {
        //     appName: filename,
        //     version,
        //     url: objectUrl
        // })
        downlinkurl(blob, filename)
    }
}

async function downloadExcelFile(url, outputPath, item, headers = {}) {
    console.log("执行了downloadFile2");
    // url = url.replace("http://112.53.105.99:20001", "downurl")
    console.log("打印url===", url);
    item.is_down = true;
    // let startByte = 0;

    // if (fs.existsSync(outputPath)) {
    //     // 如果已经存在输出文件，则获取已下载的文件大小
    //     const stats = fs.statSync(outputPath);
    //     startByte = stats.size;

    //     // 通过设置 Range 请求头来指定从哪个位置继续下载
    //     headers['Range'] = `bytes=${startByte}-`;
    // }

    // const writer = fs.createWriteStream(outputPath, { flags: 'a' });

    // const response = await axios.get(url, {
    //     responseType: 'stream',
    //     headers,
    //     responseType: "blob",
    //     onDownloadProgress: ProgressEvent => {
    //         let percentnum = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);

    //         // item.rate = Number(percentComplete.toFixed(2));
    //         item.rate = parseInt(percentnum);
    //         item.rate_text = item.rate + "%";
    //     }
    // });

    // response.data.pipe(writer);

    // // 返回一个 Promise，以便在外部处理下载过程
    // return new Promise((resolve, reject) => {
    //     writer.on('finish', resolve);
    //     writer.on('error', reject);
    // });

    axios({
        url: url,
        method: "get",
        responseType: "blob",
        onDownloadProgress: ProgressEvent => {
            let percentnum = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);

            // item.rate = Number(percentComplete.toFixed(2));
            item.rate = parseInt(percentnum);
            item.rate_text = item.rate + "%";
        }
    }).then(res => {

        item.is_down = false;
        console.log("下载完成111", res);
        saveAs(res.data, outputPath, item.version)
    }).catch(err => {
        item.is_down = false
    })

}

export function downloadFile(url, fileName, item) {
    getBlob(url, item).then((blob) => {
        saveAs(blob, fileName, item.version);
    });
}

function getBlob(url, item) {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response);
            }
        };

        xhr.addEventListener("progress", function (event) {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                item.is_down = true;
                // item.rate = Number(percentComplete.toFixed(2));
                item.rate = parseInt(percentComplete);
                item.rate_text = item.rate + "%";
            }
        });

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                // 下载完成
                item.is_down = false;
                console.log("下载完成222");
            }
        };
        xhr.send();
    });
}


function setLocalStore(key, obj) {
    let app_list = localStorage.getItem(key) && JSON.parse(localStorage.getItem(key));

    let item = app_list && app_list.find(x => x.appName == obj.appName)
    if (item) {
        //有相同的应用信息
        if (item.version != obj.version) {
            //版本不同
            item = obj
            store.handle_apps_info(item)
            // localStorage.setItem(key, JSON.stringify(app_list))
        }
    } else {
        //没有相同的应用信息
        if (app_list) {
            app_list.push(obj)
        } else {
            app_list = [obj]
        }
        // localStorage.setItem(key, JSON.stringify(app_list))
    }

}

export function format_app(list) {
    let zimu_List_all = []
    list.map((x) => {
        x["pinyin"] = pinyin.getFullChars(x.appName[0]);
        let first = x.pinyin[0];
        let item = zimu_List_all.find((x) => x.zimu == first);
        if (item) {
            item.children.push(x);

        } else {
            zimu_List_all.push({ zimu: first, children: [x] });
        }
        return x;
    });

    zimu_List_all.value = zimu_List_all.sort((a, b) => {
        if (a.zimu < b.zimu) {
            return -1;
        }
        if (a.zimu > b.zimu) {
            return 1;
        }
        return 0;
    });

    return zimu_List_all
}
const request = window.indexedDB.open('myDatabase', 1);
request.onupgradeneeded = function () {
    const db = request.result;
    const store = db.createObjectStore('binaryStore', { keyPath: 'id' });
    const objectStore = db.createObjectStore("downloadStore", { keyPath: "id" });
};
//保存进度


function deleteProgress(id) {
    const transaction = request.result.transaction(["downloadStore"], "readwrite");
    const objectStore = transaction.objectStore("downloadStore");
    objectStore.delete(id)

}

async function saveData(key, data) {
    try {
        const db = await openIndexedDB('myDatabase', 1, (db) => {
            db.createObjectStore('binaryStore', { keyPath: 'id' });
        });
        const tx = db.transaction(['binaryStore'], 'readwrite');
        const store = tx.objectStore('binaryStore');
        store.put({ id: key, data: new Blob([data]) }); // 存储 Blob 对象
        await tx.complete;
        console.log('Binary data saved successfully');
    } catch (error) {
        console.error('Error saving binary data:', error);
    }
}

async function loadBinaryData(key) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openIndexedDB('myDatabase', 1);
            const tx = db.transaction(['binaryStore'], 'readonly');
            const store = tx.objectStore('binaryStore');
            const getData = store.get(key);
            // console.log("！！！！！！！！！！！！！打印result====", result, "result.data===", result?.data);
            getData.onsuccess = async function () {
                console.log("打印getData=====", getData.result);
                const value = getData?.result?.data ? new Uint8Array(await getData.result.data.arrayBuffer()) : ""
                resolve(value);
            };
            getData.onerror = function () {
                reject(new Error('Failed to retrieve binary data'));
            };
            // if (result?.data) {
            //     const blob = result.data;
            //     let uni8Data = new Uint8Array(await blob.arrayBuffer())
            //     console.log("打印unitArray===",uni8Data);
            //     return uni8Data;
            // } else {
            //     return ""
            // }
            console.log('Binary data loaded successfully');
        } catch (error) {
            console.error('Error loading binary data:', error);
        }
    })

}

function openIndexedDB(name, version, upgradeCallback) {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(name, version)
        request.onupgradeneeded = (event) => {
            const db = event.target.result
            upgradeCallback(db)
        }
        request.onsuccess = (event) => {
            resolve(event.target.result)
        }
        request.onerror = (event) => {
            reject(event.target.error)
        }
    })
}



//读取进度
export async function getProgress(key) {
    console.log("读取的key====", key);
    return new Promise((resolve, reject) => {
        const transaction = request.result.transaction(['downloadStore'], 'readonly');
        const store = transaction.objectStore('downloadStore');
        const getData = store.get(key);
        getData.onsuccess = function () {

            let data = getData?.result || ""
            console.log("getProgress======", getData.result, "data=====", data);
            resolve(data);
        };
        getData.onerror = function () {
            reject(new Error('Failed to retrieve binary data'));
        };
    });

}

export async function saveProgress(id, downloadedSize, total) {

    console.log("打印id=======", id, "downloadedSize===", downloadedSize, "total===", total);
    const transaction = request.result.transaction(["downloadStore"], "readwrite");
    const objectStore = transaction.objectStore("downloadStore");
    try {
        objectStore.put({ id, downloadedSize, total });
    } catch (error) {
        console.log("打印objectStroe====", objectStore);
    }
    // const transaction = request.result.transaction(['binaryStore'], 'readwrite');
    // const store = transaction.objectStore('binaryStore');
}

// 保存二进制数据
export function saveBinaryData(binaryData, key) {
    console.log("保存的key====", key);
    const transaction = request.result.transaction(['binaryStore'], 'readwrite');
    const store = transaction.objectStore('binaryStore');
    store.add({ id: key, data: binaryData });
}

//删除二进制数据
export function deleteBinaryData(key) {
    console.log("保存的key====", key);
    const transaction = request.result.transaction(['binaryStore'], 'readwrite');
    const store = transaction.objectStore('binaryStore');
    store.delete(key)
}
// 读取二进制数据
export function getBinaryData(key) {
    console.log("读取的key====", key);
    return new Promise((resolve, reject) => {
        const transaction = request.result.transaction(['binaryStore'], 'readonly');
        const store = transaction.objectStore('binaryStore');
        const getData = store.get(key);
        getData.onsuccess = function () {

            resolve(getData.result ? getData.result.data : null);
        };
        getData.onerror = function () {

            reject(new Error('Failed to retrieve binary data'));
        };
    });
}