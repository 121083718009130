import axios from "axios"


import router from "@/router"
import { useUserStore } from "@/store/user";
import { showNotify } from 'vant';
// import { useUserStore } from "@/store/user";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000,
  // headers: { 'Content-Type': 'application/json;charset=utf-8' }
})

let loading;
let requestCount = 0
const showLoading = () => {
  if (requestCount == 0 && !loading) {

    loading = ElLoading.service({
      text: "加载中...",
      background: "rgba(0,0,0,0.7)",
      spinner: "el-icon-loading",
    });
  }
  requestCount++
}
const hideLoading = () => {
  requestCount--
  if (requestCount == 0) {
    loading.close()
    loading = ""
  }
}

service.interceptors.request.use((config) => {
  // showLoading();
  // const userStore = useUserStoreHook();
  if (localStorage.getItem("token_sys")) {
    config.headers['Authorization'] = localStorage.getItem("token_sys");
  }

  let timestamp = Date.parse(new Date()) / 1000

  // if (config.url.indexOf('?') > -1) {
  //   config.url += `&n=${timestamp}`
  // } else {
  //   config.url += `?n=${timestamp}`
  // }
  // config.headers['system-type'] = 3
  return config
}, err => {
  // hideLoading()
  return Promise.reject(err)
})

let is_show_error = false
service.interceptors.response.use((response) => {
  let userStore = useUserStore()
  console.log("打印response===", response);
  const { code, msg } = response.data
  if (response.data instanceof ArrayBuffer) {
    return response
  }



  if (code && code != '200') {

    if (code == 600) {
      router.push("/login")
      localStorage.removeItem("token_sys");
      localStorage.removeItem("userInfo_sys");
      userStore.set_user_info("")
      if (!is_show_error) {
        // ElMessage({ message: msg, type: "warning" })
        is_show_error = true
        setTimeout(() => {
          is_show_error = false
        }, 3000);
      }
    } else {
      // showNotify({ type: 'warning', message: msg })
      userStore.set_toast_mode({ type: "error", message: msg })
    }
  }
  // hideLoading()
  return response.data
  // ElMessage.error(msg || '系统出错')
  // return Promise.reject(new Error(msg || 'Error'))
}, err => {
  return Promise.reject(err);

})

export default service