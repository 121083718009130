import { createApp } from 'vue';
import { Button } from 'vant';
import { Calendar } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { TimePicker } from 'vant';
import { Picker } from 'vant';
import { Toast } from 'vant';
import { Form, Field, CellGroup } from 'vant';
import { Loading } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Overlay } from 'vant';
import { Popup } from 'vant';
import { Circle } from 'vant';
import { Notify } from 'vant';
import { Slider } from 'vant';
import { Rate } from 'vant';
import { vanTabs } from 'vant';
import { vanTab } from 'vant';
const app = createApp();
app.use(vanTabs)
app.use(vanTab)
app.use(Rate)
app.use(Button);
app.use(Calendar);
app.use(Swipe);
app.use(SwipeItem);
app.use(TimePicker);
app.use(Picker);
app.use(Toast);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Loading);
app.use(Radio);
app.use(RadioGroup);
app.use(Overlay);
app.use(Popup);
app.use(Circle);
app.use(Notify);
app.use(Slider);
