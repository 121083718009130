import request from "./request"

// export function loginApi(data) {
//   return request({
//     url: "/api/user/login",
//     method: "post",
//     data
//   })
// }

export function get_room(params) {
  return request({
    url: "api/rooms",
    method: "get",
    params
  })
}

export function set_room(data, method) {
  return request({
    url: "api/rooms",
    method,
    data
  })
}

export function login(data) {
  return request({
    url: "api/oauth/Login",
    method: "post",
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}

export function getTicket(data) {
  return request({
    url: "/api/oauth/getTicket",
    method: "get",
    data
  })
}

export function getLoginConfig() {
  return request({
    url: "/api/oauth/getLoginConfig",
    method: "get"
  })
}


export function getAppList() {
  return request({
    url: "api/oauth/getAppList",
    method: "post"
  })
}

export function getFrontAppList() {
  return request({
    url: "api/oauth/front/getAppList",
    method: "get"
  })
}

export function getCloumnList() {
  return request({
    url: "/api/example/SitesCloumn/front/getlist",
    method: "get"
  })
}


// 
export function getGameCategory() {
  return request({
    url: "/api/oauth/getGameCategory",
    method: "post"
  })
}

export function getAppListByCategory(data) {
  return request({
    url: "/api/oauth/getAppListByCategory/" + data,
    method: "post"
  })
}


export function getSoftwareCategory() {
  return request({
    url: "/api/oauth/getSoftwareCategory",
    method: "post"
  })
}



export function BaseInfo() {
  return request({
    url: "/api/permission/Users/Current/BaseInfo",
    method: "get"
  })
}

export function getlunbo() {
  return request({
    url: "/api/oauth/front/getlunbo",
    method: 'get'
  })
}

export function getPhoneCode(phone) {
  return request({
    url: "/api/oauth/getPhoneCode/" + phone,
    method: "get"
  })
}

export function codeExpired(phone) {
  return request({
    url: "/api/oauth/getPhoneCode/codeExpired/" + phone,
    method: "get"
  })
}

export function registeredUser(data) {
  return request({
    url: "/api/oauth/registeredUser",
    method: "post",
    data
  })
}

export function logout() {
  return request({
    url: "/api/oauth/Logout",
    method: "GET"
  })
}

export function app_detail(params) {
  return request({
    url: "/api/appManage",
    method: "GET",
    params
  })
}

export function down(data) {
  return request({
    url: "/api/app_common/down",
    method: "post",
    data
  })
}

export function ispinglun(params) {
  return request({
    url: "/api/app_common/ispinglun",
    method: "get",
    params
  })
}

export function app_common(data) {
  return request({
    url: "/api/app_common/common",
    method: "post",
    data
  })
}

export function getPinglunList(params) {
  return request({
    url: "/api/app_common/getPinglunList",
    method: "get",
    params
  })
}

export function set_suggest(data) {
  return request({
    url: "/api/app_suggest/create",
    method: "post",
    data
  })
}
export function get_suggest(params) {
  return request({
    url: "/api/app_suggest/getList",
    method: "get",
    params
  })
}

export function addBussiness(data){
  return request({
    url:"/api/bussiness/create",
    method:"post",
    data
  })
}

