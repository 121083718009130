
window.onload = (function (doc, win) {
    var maxWidth = 640;
    var minWidth = 320;
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= maxWidth) {
                docEl.style.fontSize = '100px';  //100px等于1rem
            } else if (clientWidth <= minWidth) {
                docEl.style.fontSize = 100 * (minWidth / maxWidth) + 'px';
                // console.log("打印=====屏幕宽度小于最小宽度", 100 * (minWidth / maxWidth) + 'px');
            }
            else {
                docEl.style.fontSize = 100 * (clientWidth / maxWidth) + 'px';
                // console.log("打印=====屏幕宽度宽度", 100 * (clientWidth / maxWidth) + 'px');
            }
            // if (screenWidth != document.body.clientWidth) {
            //     console.log("执行11111=");
            //     screenWidth = document.body.clientWidth
            //     var clientWidth = docEl.clientWidth;
            //     if (!clientWidth) return;
            //     if (clientWidth >= maxWidth) {
            //         docEl.style.fontSize = '100px';  //100px等于1rem
            //     } else if (clientWidth <= minWidth) {
            //         docEl.style.fontSize = 100 * (minWidth / maxWidth) + 'px';
            //         console.log("打印=====屏幕宽度小于最小宽度", 100 * (minWidth / maxWidth) + 'px');
            //     }
            //     else {
            //         docEl.style.fontSize = 100 * (clientWidth / maxWidth) + 'px';
            //         console.log("打印=====屏幕宽度宽度", 100 * (clientWidth / maxWidth) + 'px');
            //     }
            // }


            // location.reload()
        };
    recalc();
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);