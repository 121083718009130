import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './store'
import './assets/rem'
// import 'amfe-flexible'
// import "./permission"
import "@/assets/reset.css"
import "@/ui/vantui"
import 'vant/es/toast/style';
import "@/assets/icon.css"
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
// import "@/assets/icon.css"

// import permission_fn from "@/permission/permission"


const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
// Import Swiper styles
import 'swiper/css';
import componentsRegister from './plugins/componentsRegister'
const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn
})
// permission_fn(app)
// app.directive('permission', permission_fn)
app.use(pinia).use(router).use(componentsRegister).mount('#app')
