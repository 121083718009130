/* unplugin-vue-components disabled */import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment } from "vue";
import { useUserStore } from "@/store/user";
import { onMounted, watch } from "vue";
import { onUnmounted } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    let is_text = ref(true);
    setTimeout(() => {
      is_text.value = false;
    }, 2000);
    let useStore = useUserStore();
    watch(() => {
      return useStore.show_mode;
    }, val => {
      if (val) {
        setTimeout(() => {
          useStore.show_mode = false;
        }, useStore.toast_mode.duration * 1000);
      }
    });
    console.log("打印NODE_ENV", process.env.NODE_ENV);
    console.log("打印VUE_APP_TITLE", process.env.VUE_APP_TITLE);
    console.log("打印VUE_APP_PORT", process.env.VUE_APP_PORT);
    console.log("打印VUE_APP_BASE_URL", process.env.VUE_APP_BASE_URL);
    let screenWidth = document.body.clientWidth;
    if (screenWidth > 640) {
      useStore.set_seen(true);
    } else {
      // this.seen = false;
      useStore.set_seen(false);
    }
    window.addEventListener("resize", () => {
      let now_seen = useStore.seen;
      let screenWidth_ = document.body.clientWidth;
      if (now_seen && screenWidth_ < 640) {
        useStore.set_seen(false);
      }
      if (!now_seen && screenWidth_ > 640) {
        useStore.set_seen(true);
      }
      console.log("改变了现在useStore是====", useStore.seen);
    });
    const detectDeviceType = () => {
      const userAgent = navigator.userAgent;
      // userAgent_ref.value = userAgent;
      console.log("打印userAgent========", userAgent);
      let device = "Unknown";

      // 检测设备类型
      if (/Android/i.test(userAgent)) {
        device = "Android";
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        device = "iOS";
      } else if (/Windows/i.test(userAgent)) {
        device = "Windows";
      } else if (/Windows Phone/i.test(userAgent)) {
        device = "Windows Phone";
      } else if (/BlackBerry/i.test(userAgent)) {
        device = "BlackBerry";
      } else if (/webOS/i.test(userAgent)) {
        device = "webOS";
      } else if (/Opera Mini/i.test(userAgent)) {
        device = "Opera Mini";
      } else if (/SamsungBrowser/i.test(userAgent)) {
        device = "Samsung";
      } else if (/Chrome/i.test(userAgent)) {
        device = "Chrome";
      } else if (/Firefox/i.test(userAgent)) {
        device = "Firefox";
      } else if (/Safari/i.test(userAgent)) {
        device = "Safari";
      }
      useStore.set_device(device);
      console.log("打印device=====", device);
      // deviceType.value = device;
    };

    detectDeviceType();

    // let screenWidth = window.innerWidth;

    // let router = useRouter();
    // if (screenWidth < 1000) {
    //   router.push("/phone");
    // } else {
    //   router.push("/");
    // }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: route.fullPath
        }))], 1024))]),
        _: 1
      }), _createVNode(_Transition, null, {
        default: _withCtx(() => [_unref(useStore).show_mode ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['mode_prop', _unref(useStore).toast_mode.type])
        }, _toDisplayString(_unref(useStore).toast_mode.message), 3)) : _createCommentVNode("", true)]),
        _: 1
      })], 64);
    };
  }
};